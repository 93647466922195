import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/HC1" element={<HC1 />} />
      <Route path="/HC2" element={<HC2 />} />
      <Route path="/ALF" element={<ALF />} />
      <Route path="/PLOPS" element={<PLOPS />} />
      <Route path="/OBDin" element={<OBDin />} />
      <Route path="/OB2F" element={<OB2F />} />
      <Route path="/ADM" element={<ADM />} />
      {/* <Route path="/?" element={<TC4 />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

function Home() {
  return (
    <h1>Time Clock URL Shortener</h1>
  )
}

function HC1() {
  getLongURL("HC1").then((url) => {
    console.log("URL:" + url)
    window.location.assign(url)
  })
}

function HC2() {
  getLongURL("HC2").then((url) => {
    console.log("URL:" + url)
    window.location.assign(url)
  })
}

function ALF() {
  getLongURL("ALF").then((url) => {
    console.log("URL:" + url)
    window.location.assign(url)
  })
}

function PLOPS() {
  getLongURL("PLOPS").then((url) => {
    console.log("URL:" + url)
    window.location.assign(url)
  })
}

function OBDin() {
  getLongURL("OBDin").then((url) => {
    console.log("URL:" + url)
    window.location.assign(url)
  })
}

function OB2F() {
  getLongURL("OB2F").then((url) => {
    console.log("URL:" + url)
    window.location.assign(url)
  })
}

function ADM() {
  getLongURL("ADM").then((url) => {
    console.log("URL:" + url)
    window.location.assign(url)
  })
}

async function getLongURL(string) {
  return new Promise(async (resolve) => {

    const url = "https://api.jsonbin.io/v3/b/64624a788e4aa6225e9d8fae"

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "X-Access-Key": "$2b$10$lROXOsFhfOaVDf1vEjnLZODxhGTg8Tfl4FzOggX90VsVWgxnSjZz.",
      }
    });

    const json = await response.json()
    const redirectTo = json.record[string]
    resolve(redirectTo)
  })
}

export default App;
